/**
 * Filters items based on certain criteria.
 * It removes experts that have matching hosts with the same host_id.
 * @param {Array} items - The array of items to be filtered.
 * @returns {Array} - The filtered array of items.
 */
export function filterItems(items) {
  return items.filter((item) => {
    const isExpert = item.objectID.startsWith("expert-")

    // Check if the item is an expert and if there is an existing host with the same host_id.
    const hasMatchingHost = items.some((i) =>
      i.objectID.startsWith("host-") &&
      item.host_id === i.host_id
    )

    // Filter out experts that have matching hosts.
    if (isExpert && hasMatchingHost) {
      return false
    }
    return true
  })
}
